<template>
    <!-- Info: Input Fields -->
    <b-row>
        <b-col cols="12" md="4">
            <!-- Name -->
            <validation-provider #default="validationContext" name="name" rules="required">
                <b-form-group :label="$t('country.name')" label-for="name">
                    <b-form-input autofocus trim
                                  id="name"
                                  v-model="modelData.name"
                                  :state="getValidationState(validationContext)"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :placeholder="$t('country.name')" />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
        </b-col>

        <b-col cols="12" md="4">
            <!-- Code -->
            <validation-provider #default="validationContext" name="code" rules="required">
                <b-form-group :label="$t('country.code')" label-for="code">
                    <b-form-input autofocus trim
                                  id="code"
                                  v-model="modelData.code"
                                  :state="getValidationState(validationContext)"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :placeholder="$t('country.code')" />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </validation-provider>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4" v-if="modelData.id">
            <b-form-group :label="$t('country.status')"
                          label-for="status">
                <v-select v-model="modelData.status"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="status"
                          :placeholder="$t('country.status')" />
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
    import {
        BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormInvalidFeedback
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required, alphaNum, email } from '@validations'
    import formValidation from '@core/comp-functions/forms/form-validation'
    import vSelect from 'vue-select'
    import useList from '../list/useList'

    export default {
        components: {
            BButton,
            BMedia,
            BAvatar,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BTable,
            BCard,
            BCardHeader,
            BCardTitle,
            BFormCheckbox,
            vSelect,
            // Form Validation
            ValidationProvider,
            ValidationObserver,
            BFormInvalidFeedback,
        },
        props: {
            modelData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                required,
                alphaNum,
                email,
            }
        },
        setup(props) {

            const { statusOptions } = useList();
            const resetData = () => {
                this.modelData.value = {};
            }
            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetData)

            return {
                statusOptions,

                refFormObserver,
                getValidationState,
                resetForm,
            }
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
